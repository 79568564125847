<template>
  <banded-section
    class="MentionsQA"
    title="Mentions QA"
    :collapsable="false"
  >
    <div class="MentionsQA__filters columns">
      <div class="column is-2">
        <form-field label="Media Type" :required="true">
          <el-select
            v-model="form.mediaType"
            filterable
            value-key="id"
          >
            <el-option
              v-for="mediaType in allowedMediaTypes"
              :key="mediaType.id"
              :label="mediaType.name"
              :value="mediaType.id"
            >
              {{ mediaType.name }}
            </el-option>
          </el-select>
        </form-field>
      </div>

      <div class="column is-4">
        <form-field label="Publication date" :required="true">
          <el-date-picker
            v-model="form.dateFrom"
            :disabled="!form.mediaType"
            :picker-options="startPickerOptions"
            class="is-width-full"
            value-format="yyyy-MM-dd"
          />

          {{ $t('components.main_dashboard.dashboard_filters.to') }}

          <el-date-picker
            v-model="form.dateTo"
            :disabled="!form.mediaType"
            :picker-options="endPickerOptions"
            class="is-width-full"
            value-format="yyyy-MM-dd"
          />
        </form-field>
      </div>

      <div class="column is-3">
        <form-field label="Media Title search" :required="true">
          <input
            v-model="form.mediaTitle"
            class="input"
            type="text"
            name="mediaTitle"
            :disabled="!form.mediaType"
          >
        </form-field>
      </div>

      <div class="column is-3">
        <form-field label="Search Terms" :required="true">
          <tags-list
            v-model="form.terms"
            :disabled="!form.mediaType"
            name="terms"
          />
        </form-field>
      </div>
    </div>

    <div v-if="shouldShowResults" class="mt-5">
      <hr>

      <load-more
        method="post"
        endpoint="/staff/mentions-qa/mentions"
        :data-payload="fetchMentionsPayload"
        :debounce="500"
        width="column is-12"
        placeholder="MentionPlaceholder"
        @loaded="mentionsCached = $event"
      >
        <template #item="{ item: mention }">
          <mention-item-base
            :show-harvest-date="true"
            :media-title-name="mention.media_title_name"
            :content-title="mention.content_title"
            :content-body-highlighted="mention.content_body_highlighted"
            :content-body="mention.content_body"
            :published-at="mention.published_at"
            :harvested-at="mention.harvested_at"
            :countries="mention.countries"
            :languages="mention.languages"
            :presence="mention.presence"
            :sentiment="mention.sentiment"
            :type="mention.type"
            :impressions="mention.impressions"
            :visits-pr-month="mention.visits_pr_month"
            :estimated-clicks="mention.estimated_clicks"
            :image="mention.thumbnail"
            :source="mention.source_link"
            :is-advertisement="mention.is_advertisement"
            class="Mention-item"
            data-testid="mentionItemCard"
          />
        </template>
      </load-more>
    </div>
    <div v-else>
      <template v-if="!form.mediaType">
        Please select a media type
      </template>
      <template v-else>
        Please type a search term
      </template>
    </div>
  </banded-section>
</template>

<script>
import { format, differenceInDays, subDays } from 'date-fns'

import TagsList from '@/components/core/TagsList'

import MentionItemBase from '@hypefactors/shared/js/components/streams/MentionItemBase'

export default {
  name: 'MentionsQA',

  components: {
    TagsList,
    MentionItemBase
  },

  data () {
    return {
      isLoadingMediaTitles: false,
      isLoadingMentions: false,
      form: {
        mediaType: null,
        dateFrom: format(subDays(Date.now(), 30), 'YYYY-MM-DD'),
        dateTo: format(Date.now(), 'YYYY-MM-DD'),
        mediaTitle: '',
        terms: []
      },
      allowedMediaTypes: [
        { id: 'web', name: 'Web' },
        { id: 'print', name: 'Print' }
      ],
      allowedCountries: ['UK', 'DK', 'NO', 'SE', 'FR', 'FI', 'PT'],
      mentionsCached: []
    }
  },

  computed: {
    shouldShowResults () {
      return this.form.mediaType && (this.form.mediaTitle !== '' || this.form.terms)
    },

    filterMediaTitles () {
      return `${this.form.mediaType}|${this.form.dateFrom}|${this.form.dateTo}`
    },

    fetchMentionsPayload () {
      return {
        media_type: this.form.mediaType,
        date_from: this.form.dateFrom,
        date_to: this.form.dateTo,
        media_title: this.form.mediaTitle,
        terms: this.form.terms
      }
    },

    startPickerOptions () {
      return {
        firstDayOfWeek: 1,
        disabledDate: (time) => {
          return time.getTime() > Date.now()
        }
      }
    },

    endPickerOptions () {
      return {
        firstDayOfWeek: 1,
        disabledDate: (time) => {
          const diff = differenceInDays(time, this.form.dateFrom)
          return diff > 30 || diff === 0
        }
      }
    }
  }
}
</script>
